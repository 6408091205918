import {useEffect,useState} from 'react';
import React from 'react';
import {Button} from '@aws-amplify/ui-react';
import { Loader, Badge } from '@aws-amplify/ui-react';

import { v4 as uuidv4 } from 'uuid';
import {API} from 'aws-amplify';

// uuidv4(); //  '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'

const ouraClientId='JEHYLULXGWY3QQXD';
const apiName = 'ouraauthorizer';

function MonitorOura({user, signOut}) {
  const [ouraAuthStatus, setOuraAuthStatus] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(()=> {

    async function init() {
      //console.log(user);
      setLoading(true);
      try {
        const myInit = {
          headers: {}, // OPTIONAL
          //response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {
          }
        };

        const data = await API.get(apiName, '/api/ourainfo', myInit);
        console.log(data.success);
        setOuraAuthStatus(Array.isArray(data.success) ? data.success[0] : null);
      } catch (err) {
        console.log('Error', err);
      }
      setLoading(false);

    }

      init();



    return () => {
      try {
      } catch (e) {
        console.log('error unmounting', e);
      };
    }

  },[]);

  const handleOuraAuthorize = async () => {
    try {

      setLoading(true);

      const redirectUri = encodeURIComponent("https://64djxrd97l.execute-api.ap-southeast-2.amazonaws.com/dev/callback");
      const scope = encodeURIComponent('personal daily heartrate workout tag session');
      const session = uuidv4();
      const state = user.username + '-' + session;
      const url = `https://cloud.ouraring.com/oauth/authorize?response_type=code&client_id=${ouraClientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
      console.log(url);
      // 


      const myInit = {
        body: {
          authsession: session
        }
      };

      const data = await API.post(apiName, '/api/initouraauth', myInit);
      console.log(data);
      //window.alert('You will be Redirected to Oura to authorise SMART SAUNAS app.\n' + JSON.stringify(data));

      window.location.href=url;

    } catch (err) {
      window.alert('An unexpected error occured...');
      console.log('Error', err);
      setLoading(false);
    }
    // setLoading(false);


  }

  const handleGetUserInfo = async () => {
    try {

      setLoading(true); 

      let userinfo = await API.get(apiName, '/api/oura/userinfo');
      console.log(userinfo);

      if (userinfo.body.status === 401)
      {
        console.log('renewing...');
        const refreshed = await API.get(apiName, '/api/oura/renewmytoken');
        console.log(refreshed);
        userinfo = await API.get(apiName, '/api/oura/userinfo'); //retrying
        console.log(userinfo);

      }

      const sleepdata = await API.get(apiName, '/api/oura/savedailysleep?start_date=2023-01-01&end_date=2023-04-04',); //retrying
      console.log(sleepdata);

      

    } catch (err) {
      window.alert('An unexpected error occured...');
      console.log('Error', err);
    }
    setLoading(false);


  }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '1rem', width: '90%', margin: 'auto', textAlign: 'left' }}>
            <h1>Hi {user.attributes?.given_name}</h1>
            {loading ? <Loader size='large' /> : ( (ouraAuthStatus && ouraAuthStatus.oura_access_token) ? (
              <div>
              <p>You have successfully linked your oura account with the following scopes: </p>
              {ouraAuthStatus.oura_scopes.map((s) => (<Badge key={'b-'+s} style={{marginRight:'0.5rem'}}variation='success'>{s}</Badge>))}
              </div>
            ) : (
               <Button onClick={handleOuraAuthorize} isLoading={loading} loadingText="Linking with Oura"> Connect my OURA ring</Button>
            ))}
            <p></p>
            <Button variation="primary" onClick={handleGetUserInfo}>Get my info</Button>
            <Button variation="destructive" onClick={signOut}>Sign out</Button>

        </div>
    );
}

export default MonitorOura;








