//import logo from './logo.svg';
//import './App.css';
import {useEffect,useReducer, useState} from 'react';
import {SubscribeToSaunaThing} from '../IoTSubscribe';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  TimeSeriesScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {API} from 'aws-amplify';
import { Badge, TextField} from '@aws-amplify/ui-react';



ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  TimeScale,
  TimeSeriesScale
);

export const CHART_COLORS = {
  red: 'rgb(255, 99, 132)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(75, 192, 192)',
  blue: 'rgb(54, 162, 235)',
  purple: 'rgb(153, 102, 255)',
  grey: 'rgb(201, 203, 207)'
};

const initChartData = {
  labels: [],
  ringsAround: [],
  datasets: [
    {
      label: 'Temperature',
      borderColor: CHART_COLORS.blue,
      backgroundColor: CHART_COLORS.blue,
      data: []
    },
    {
      label: 'Humidity',
      borderColor: CHART_COLORS.red,
      backgroundColor: CHART_COLORS.red,
      data:  []
    },
    {
      label: 'Distance',
      borderColor: CHART_COLORS.orange,
      backgroundColor: CHART_COLORS.orange,
      data: []
    }
  ],
};

const getOptions = (title) => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: title,
    },
  }
});


function reducer(state, action) {
  switch (action.type) {
    case 'cleanData':
      return initChartData;
    case 'backendfetch':
      const newLabels= action.payload.map((elem) => ((new Date(elem.senseTime)).toLocaleTimeString()));
      const newTemps=action.payload.map((elem) => (elem.temperature.value));
      const newHumids= action.payload.map((elem) => (elem.humidity.value));
      const newDists= action.payload.map((elem) => (elem.distance.value));
      return {
        labels: newLabels.concat(state.labels),
        ringsAround: action.payload[action.payload.length - 1]?.bluetooth?.value || [] ,
        datasets: [
          {
            ...state.datasets[0],
            data:  newTemps.concat(state.datasets[0].data)
          },
          {
            ...state.datasets[1],
            data:  newHumids.concat(state.datasets[1].data)
          },
          {
            ...state.datasets[2],
            data: newDists.concat(state.datasets[2].data)
          }       
        ]
      };
    case 'newdatum':
      const timePoint=new Date(action.payload.senseTime);
      return {
        labels: state.labels.concat(timePoint.toLocaleTimeString()),
        ringsAround: action.payload.bluetooth.value?.length > 0 ?  action.payload.bluetooth.value: state.ringsAround ,
        datasets: [
          {
            ...state.datasets[0],
            data: state.datasets[0].data.concat(action.payload.temperature.value)
            // data: state.datasets[0].data.concat({
            //   x: timePoint.getTime(),
            //   y: action.payload.temperature.value
            // })
          },
          {
            ...state.datasets[1],
            data:  state.datasets[1].data.concat(action.payload.humidity.value)
          },
          {
            ...state.datasets[2],
            data: state.datasets[2].data.concat(action.payload.distance.value)
          }       
        ]
      };
    default:
      throw new Error();
  }
}


function MonitorSauna() {
  const [chartData, dispatch] = useReducer(reducer, initChartData);
  const [deviceName, setDeviceName] =useState('device01');
 

  useEffect(()=> {
    let connection;
    let mounted=true;

    async function fetchLastHour() {

      const apiName = 'ouraauthorizer';
      const path = '/devicedata';
      const myInit = {
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {
          name: 'param' // OPTIONAL
        }
      };
      dispatch({ type: 'cleanData' });
      const response= await API.get(apiName, path+'/'+deviceName, myInit);
      console.log(response.data);
      if (mounted) {
        console.log('dispatching backend... ');
        dispatch({ type: 'backendfetch', payload: response.data });
      }

    }

      fetchLastHour();


    async function subscribeToLiveFeed() {
      connection = await SubscribeToSaunaThing(deviceName,(data) => {
        console.log(data);
        if (mounted === false)
          {
            connection.disconnect();
            console.log('disconnected in callback!');
          }
        else
          {
              // console.log('dispatching', data);
              dispatch({ type: 'newdatum', payload: data });
          }
      });
    }

    subscribeToLiveFeed();
    return () => {
      try {
        mounted=false;
        connection.disconnect();
        console.log('disconnected!');
      } catch (e) {
        console.log('error unmounting', e);
      };
    }

  },[deviceName]);

  const handleDeviceChange = (e) => {
    setDeviceName(e.currentTarget.value)
  }


  console.log('rendering...');
  //console.log(chartData.datasets[0]);
  return (
      <div style={{display:'flex', flexDirection:'column',  paddingTop:'1rem', width:'90%', margin:'auto'}}>
            
      <TextField label="Device ID" labelHidden={true} value={deviceName} onChange={handleDeviceChange} />

        <span>{chartData.ringsAround.map((r)=>(<><Badge>{r.name+':'}</Badge><Badge variation='success'>{r.value}</Badge></>))}</span>
        <div style={{ flex: 1, marginTop:'1rem' }}>
          <Line options={getOptions('Temperature (°C)')} data={{
            labels: chartData.labels,
            datasets: [chartData.datasets[0]]
          }} />
        </div>
        <div style={{ flex: 1, marginTop:'1rem' }}>
          <Line options={getOptions('Humidity (%RH)')} data={{
            labels: chartData.labels,
            datasets: [chartData.datasets[1]]
          }} />
        </div>
        <div style={{ flex: 1, marginTop:'1rem' }}>
          <Line options={getOptions('Distance (cm)')} data={{
            labels: chartData.labels,
            datasets: [chartData.datasets[2]]
          }} />
        </div>              

      </div>
  );
}

export default MonitorSauna;








