//import logo from './logo.svg';
import './App.css';
import {useEffect} from 'react';
import React from 'react';
// import {API} from 'aws-amplify';
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import MonitorSauna from './components/MonitorSauna';
import MonitorOura from './components/MonitorOura';
import { Authenticator, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';





function App() {
  const navigate = useNavigate();

  useEffect(()=> {

    return () => {
    }

  },[]);

  return (
    <div className="App">
        <Routes>
        <Route>
            <Route path="/" element={<div style={{textAlign:'center'}}>
              <Button onClick={()=> {navigate('/oura')}} style={{marginTop:'1rem'}}>
                Link health data (OURA)
              </Button>
                <MonitorSauna />
            </div>} />
          <Route path="/oura" element={<Authenticator>
            {({ signOut, user }) => {
              return (
                <MonitorOura user={user} signOut={signOut} />
            )}}
          </Authenticator>}  />

          <Route path='*' element={<h1>404 Not Found!</h1>} />
        </Route> 
      </Routes>
      <div style={{bottom: '1rem', margin: '0 1rem', fontSize:'x-small', textAlign:'center' }}>
       <a target="_blank" href="https://icons8.com/icon/24691/sauna" rel="noreferrer">Sauna</a> icon by <a target="_blank" href="https://icons8.com" rel="noreferrer">Icons8</a>
      </div>
    </div>
  );
}

export default App;








