import { mqtt, iot, auth } from "aws-iot-device-sdk-v2";
import * as AWS from "aws-sdk";
import * as Settings from "./settings";
import awsconfig from './aws-exports';

export class AWSCognitoCredentialsProvider extends auth.CredentialsProvider{
    // private options;
    // private source_provider;
    // private aws_credentials;
    constructor(options, expire_interval_in_ms)
    {
      super();
      this.options = options;
      AWS.config.region = options.Region;
      this.source_provider = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: options.IdentityPoolId
      });
      this.aws_credentials = 
      {
          aws_region: options.Region,
          aws_access_id : this.source_provider.accessKeyId,
          aws_secret_key: this.source_provider.secretAccessKey,
          aws_sts_token: this.source_provider.sessionToken
      }
  
      setInterval(async ()=>{
          await this.refreshCredentialAsync();
      },expire_interval_in_ms?? 3600*1000);
    }
  
    getCredentials(){
        return this.aws_credentials;
    }
  
    async refreshCredentialAsync()
    {
      return new Promise((resolve, reject) => {
          this.source_provider.get((err)=>{
              if(err)
              {
                  reject("Failed to get cognito credentials.")
              }
              else
              {
                  this.aws_credentials.aws_access_id = this.source_provider.accessKeyId;
                  this.aws_credentials.aws_secret_key = this.source_provider.secretAccessKey;
                  this.aws_credentials.aws_sts_token = this.source_provider.sessionToken;
                  this.aws_credentials.aws_region = this.options.Region;
                  resolve(this);
              }
          });
      });
    }
  }
  
  async function connect_websocket(provider) {
    return new Promise((resolve, reject) => {
      const clid=`web-client-(${(new Date()).getTime()})`;
      console.log(clid);
      let config = iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket()
          .with_clean_session(true)
          .with_client_id(clid)
          .with_endpoint(Settings.AWS_IOT_ENDPOINT)
          .with_credential_provider(provider)
          .with_use_websockets()
          .with_keep_alive_seconds(30)
          .build();
  
      console.log("Connecting websocket...");
      const client = new mqtt.MqttClient();
  
      const connection = client.new_connection(config);
      connection.on("connect", (session_present) => {
        resolve(connection);
      });
      connection.on("interrupt", (error) => {
        console.log(`Connection interrupted: error=${error}`);
      });
      connection.on("resume", (return_code, session_present) => {
        console.log(`Resumed: rc: ${return_code} existing session: ${session_present}`);
      });
      connection.on("disconnect", () => {
        console.log("Disconnected");
      });
      connection.on("error", (error) => {
        reject(error);
      });
      connection.connect();
    });
  }
  
 export async function SubscribeToSaunaThing(deviceID, onDataReceived) {
     try {
         /** Set up the credentialsProvider */
         const provider = new AWSCognitoCredentialsProvider({
             IdentityPoolId: awsconfig.aws_cognito_identity_pool_id,
             Region: Settings.AWS_REGION
         });
         /** Make sure the credential provider fetched before setup the connection */
         await provider.refreshCredentialAsync();


         const connection = await connect_websocket(provider);
         console.log('connected!');
         await connection.subscribe("sauna/"+deviceID+"/data", mqtt.QoS.AtLeastOnce, (topic, payload, dup, qos, retain) => {
             const decoder = new TextDecoder("utf8");
             let message = decoder.decode(new Uint8Array(payload));
             //console.log(`Message received: topic=${topic} message=${message}`);
             if (typeof onDataReceived === 'function')
                 onDataReceived(JSON.parse(message));
             /** The sample is used to demo long-running web service. 
              * Uncomment the following line to see how disconnect behaves.*/
             // connection.disconnect();
         });
         console.log('subscribed!');
         return connection;
     } catch (reason) {
         console.log(`Error while connecting: ${reason}`);
     }
      
  }
  